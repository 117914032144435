.Auth {
    /* margin-left: 20%; */
    /* width: 100%; */
    text-align: center;
    padding: 5%;
    /* max-width: 1050px; */

}

/* @media (min-width: 750px) {
    .Auth {
        min-width: 750px;
    }
} */

.AuthContainer {
    display: flex;
    padding: auto 0;
}
.LoginButtonContainer {
    padding: 3%;
    width: 100%;
  }
.LoginHeaderContainer {
    padding: 10px;
}
.LoginContainer {
    
    padding: 3%;
    width: 50%; 
    display: inline-block; 
    color: white;
}

.RegisterContainer {
    box-shadow: 0 2px 3px #ccc;
    border: 1px solid #eee;
    padding: 10%;
    box-sizing: border-box;
    margin-top: 10%;
    border-radius: 25px;
    display: inline-block;
}
.LoginFormContainer {
    align-items: center;
    position: relative;
    display:inline-block;

}
.RegisterFormContainer {
    align-items: center;
    position: relative;
    display:inline-block;
}
.LoginForm {
    text-emphasis-color: white;
    color: white;
}

.LoginLogo {
    padding: 10%;
    font-weight: bold;
    display: flex;
}

.LoginLogo img {
    height: 50px;
    width: 40%;
}