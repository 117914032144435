.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    /* width: 50%; */
    margin-right: 20px;
    font-size: 20px;
    display: inline-block;
    color: white;
}
.NavigationItem a.active {
    /* background-color: #8F5C2C; */
    /* border-bottom: 2px solid #40A4C8; */
    /* color: yellow; */
    /* font-weight: bold; */
    color: white;
}
.NavigationItem a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
    color: white;
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
        display: inline-block;
        color: white;
    }
    
    .NavigationItem a {
        /* color: #FFE146; */
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
        color: white;
    }
    
    .NavigationItem a:hover,
    .NavigationItem a:active,
    .NavigationItem a.active {
        color: white;
        /* background-color: #8F5C2C; */
        /* border-bottom: 2px solid #40A4C8; */
        /* color: yellow; */
        /* font-weight: bold; */
    }
}