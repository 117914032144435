.Toolbar {
    /* height: 66px; */
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color:black;
    /* color: white; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 0 20px ; */
    /* box-sizing: border-box; */
    z-index: 10000;
    opacity: 0.8;
    /* border-bottom-style: solid;
    border-bottom-color: white;
    border-bottom-width: 2px; */

}
.AppNameHeader{
    text-align: center;
    position: relative;
    width: 100%;
    margin-left: 15%;
    margin-bottom: 1%;
    font-size: 24px;
}


.Toolbar nav {
    height: 100%;
}

.ToolbarButton {
    /* background-color: #FFF200; */
    height: 90%;
    width: 18%;
    right: 0;
    box-sizing: border-box;
    display:inline-block;
    padding:0.46em 1.6em;
    border:0.1em solid #000000;
    /* border-radius:0.12em; */
    box-sizing: border-box;
    text-decoration:none;
    font-weight:200;
    text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
    text-align:center;
    transition: all 0.15s;
    text-decoration: none;
    color: black;
}

.ToolbarUl {
    list-style: none;
    margin: 0;
    padding:0;
    text-align: center;

}
.ToolbarLi {
    display: inline-block;
    text-align: center;
    margin: 20px;
}
.ToolbarLink {
    text-decoration: none;
    color: white;
}